import React from "react";
import { Button, Col, Form, Image, Input, Row, Space } from "antd";
import { Navigate, useNavigate } from "react-router-dom";
import apiService from "../services/apiService";

const LoginPage = (props) => {
  const navigate = useNavigate();

  const onFinish = (values) => {
    const scb = (data) => {
      props.handleLogin(data);
      navigate("/u/" + data.user.username);
    };

    const ecb = (error) => {
      console.log("error: " + JSON.stringify(error));
    };

    apiService.login(values.username, values.password).then(scb).catch(ecb);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  if (props.identity && props.identity.id) {
    return <Navigate to={"/u/" + props.identity.username} />;
  }

  return (
    <div>
      <Row gutter={[4, 4]} style={{ marginTop: "100px", textAlign: "center" }}>
        <Col span={24}>
          <Space>
            <Image width={110} src="/images/trace-logo.png" />
          </Space>
        </Col>
      </Row>
      <Row gutter={[4, 4]} style={{ marginTop: "80px", textAlign: "center" }}>
        <Col span={8} offset={8}>
          <h1>login</h1>
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={8} offset={8}>
          <Form
            name="basic"
            labelCol={{
              span: 0,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label=""
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
              ]}
            >
              <Input placeholder="username" size="large" />
            </Form.Item>

            <Form.Item
              label=""
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="password" size="large" />
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 0,
                span: 24,
              }}
            >
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                style={{ width: "100%" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default LoginPage;
