import axios from "axios";

const handleError = (error) => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    if (error.response.status === 400) {
      return error.response.data;
    } else {
      return {
        code: "http error",
        error: error.response.status + ": " + error.message,
      };
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return {
      code: "internet connection error",
      error: "no response received: " + error.message,
    };
  } else {
    // Something happened in setting up the request that triggered an Error
    return {
      code: "unknown error",
      error: error.message,
    };
  }
};

const identity = () => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/auth/identity")
      .then((response) => resolve(response.data))
      .catch((error) => reject(handleError(error)));
  });
};

const login = (username, password) => {
  const requestData = {
    username: username,
    password: password,
  };

  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/login", requestData)
      .then((response) => resolve(response.data))
      .catch((error) => reject(handleError(error)));
  });
};

const getUserdata = (username) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/user/" + username)
      .then((response) => resolve(response.data))
      .catch((error) => reject(handleError(error)));
  });
}

const getTraceData = (username, traceName) => {
  return new Promise((resolve, reject) => {
    axios
      .get("/api/trace/posts/" + username + "/" + traceName)
      .then((response) => resolve(response.data))
      .catch((error) => reject(handleError(error)));
  });
}

const logout = () => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/auth/logout")
      .then((response) => resolve({}))
      .catch((error) => reject(handleError(error)));
  });
};

const apiService = {
  getUserdata,
  getTraceData,
  identity,
  login,
  logout,
};

export default apiService;
