import React, { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import apiService from "./services/apiService";
import LoginPage from "./pages/LoginPage";
import { ConfigProvider, theme } from "antd";
import Main from "./pages/Main";
import UserPage from "./pages/UserPage";
import TracePage from "./pages/TracePage";
import "./App.css";

const App = () => {
  const [identity, setIdentity] = useState(null);

  useEffect(() => {
    const scb = (data) => {
      if (data.user) {
        setIdentity(data.user);
      }
    };

    const ecb = (error) => {
      console.log(error);
    };

    apiService.identity().then(scb).catch(ecb);
  }, []);

  const handleLogin = (data) => {
    setIdentity(data.user);
  };

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main data={identity} />,
    },
    {
      path: "/login",
      element: <LoginPage data={identity} handleLogin={handleLogin} />,
    },
    {
      path: "/u/:username",
      element: <UserPage />,
    },
    {
      path: "/u/:username/t/:traceName",
      element: <TracePage />
    }
  ]);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#f317ff",
        },
        algorithm: theme.darkAlgorithm,
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default App;
