import { Col, Image, Row } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiService from "../services/apiService";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const TracePage = () => {
  const [trace, setTrace] = useState(null);
  const [posts, setPosts] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  let { username } = useParams();
  let { traceName } = useParams();

  useEffect(() => {
    const scb = (data) => {
      setTrace(data.traces[0]);
      setPosts(data.posts);
      setIsLoading(false);
    };

    const ecb = (error) => {
      console.log(error);
    };

    apiService.getTraceData(username, traceName).then(scb).catch(ecb);
  }, []);

  if (isLoading) {
    return (
      <Row gutter={[4, 4]} style={{ marginTop: "100px" }}>
        <Col span={8} offset={8} style={{ textAlign: "center" }}>
          <h1>loading</h1>
        </Col>
      </Row>
    );
  }

  const postsView = () => {
    return posts.map((post) => {
      let markdownView = <div></div>;
      if (post.content) {
        markdownView = (
          <ReactMarkdown className="Trace-post-md" children={post.content} />
        );
      }

      return (
        <Col span={24} key={post.id}>
          <div
            style={{
              width: "640px",
              margin: "10px auto 20px auto",
              padding: "10px",
              backgroundColor: "rgba(230, 230, 230)",
            }}
          >
            <div>
              <a href={post.url} target="new_window">
                <h3>{post.title}</h3>
              </a>
            </div>

            <p>
              via:
              <a href={post.url} target="new_window">
                {post.url}
              </a>
            </p>

            {post.quote ? (
              <div>
                <div
                  style={{
                    backgroundColor: "#aaa",
                    margin: "5px 0",
                    padding: "10px",
                    fontWeight: "bolder",
                  }}
                >
                  {post.quote}
                </div>
              </div>
            ) : (
              <></>
            )}

            {post.imgUrl ? <Image src={post.imgUrl} width={"100%"} /> : <></>}

            {markdownView}
          </div>
        </Col>
      );
    });
  };

  return (
    <div>
      <Row gutter={[4, 4]}>
        <Col span={8} offset={8} style={{ textAlign: "center" }}>
          <h1>{trace.name}</h1>
        </Col>
        {postsView()}
      </Row>
    </div>
  );
};

export default TracePage;
