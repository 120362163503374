import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Avatar, Button, Col, Row, Space } from "antd";
import apiService from "../services/apiService";

const UserPage = () => {
  const [userData, setUserData] = useState(null);
  const [traces, setTraces] = useState(null);

  const navigate = useNavigate();

  let { username } = useParams();

  useEffect(() => {
    const scb = (data) => {
      setUserData({ id: data.id, username: data.username });
      setTraces(data.traces);
    };

    const ecb = (error) => {
      console.log(error);
    };

    apiService.getUserdata(username).then(scb).catch(ecb);
  }, []);

  const handleLogout = () => {
    const scb = () => {
      navigate("/login");
    };

    const ecb = (error) => {
      console.log(error);
    };

    apiService.logout().then(scb).catch(ecb);
  };

  const getTraceView = () => {
    if (traces) {
      return traces.map((trace) => {
        return (
          <div key={trace.id}>
            <h2>
              <Link to={"/u/" + username + "/t/" + trace.name}>
                {trace.name}
              </Link>
            </h2>
          </div>
        );
      });
    } else return <div></div>;
  };

  return (
    <div>
      <Row gutter={[4, 4]} style={{ marginTop: "100px" }}>
        <Col span={8} offset={8}>
          {userData ? (
            <Space>
              <Avatar size={100} src={"/api/user/" + userData.id + "/avatar"} />
              <h1 style={{ marginLeft: "50px" }}>{userData.username}</h1>
            </Space>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={8} offset={8}>
          {getTraceView()}
        </Col>
      </Row>
      <Row gutter={[4, 4]}>
        <Col span={8} offset={8}>
          <Button width="100%" onClick={handleLogout}>
            logout
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserPage;
