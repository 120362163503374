import { Col, Image, Row, Space } from "antd";

const Main = (props) => {
  return (
    <>
      <Row gutter={[4, 4]} style={{ marginTop: "100px", textAlign: "center" }}>
        <Col span={24}>
          <Space>
            <Image width={210} src="/images/trace-logo.png" />
          </Space>
        </Col>
      </Row>
      <Row gutter={[4, 4]} style={{ marginTop: "80px", textAlign: "center" }}>
        <Col span={8} offset={8}>
          <h1>traces...</h1>
        </Col>
      </Row>
    </>
  );
};

export default Main;
